.App {
  text-align: center;
  background-image: linear-gradient(
    to right bottom,
    #282c34,
    #282d34,
    #282e34,
    #282f34,
    #293034,
    #2d3337,
    #313639,
    #35393c,
    #3d3f42,
    #454648,
    #4c4c4d,
    #535353
  );
  transition: all 1s ease;
  min-height: 100vh;
  flex-direction: column;
}
.opacity {
  opacity: 0;
}
.decalP1 {
  margin-right: 1px;
}
p a {
  color: white;
  text-decoration: none;
}
p a:hover {
  color: silver;
  text-decoration: none;
}
h1 {
  text-shadow: 8px 5px 7px black;
  font-style: italic;
  font-size: 32px !important;
  font-weight: 700px;
  color: white;
  font-weight: 700 !important;
  margin: 60px 0px !important;
}

h2 {
  font-weight: bold;
  font-size: 32px;
  color: white;
  border-bottom: 1px solid black;
  width: 800px;
  margin: 50px auto !important;
  text-shadow: 8px 5px 7px black;
}
h3 {
  color: white;
  border-bottom: 1px solid white;
  width: 400px;
  margin: 0 auto;
}
.mention1 {
  display: flex;
  flex-direction: column;
  font-size: 28px;
  margin-top: 150px;
  color: white;
  margin-bottom: 173px;
}
.engagement {
  margin-top: 100px;
}
.textPresentation {
  color: white;
  width: 50%;
  margin: 0 auto;
}
.logo {
  width: 25%;
  align-self: center;
  margin: 35px 0px;
}
.carousel {
  width: 800px;
  height: auto;
  margin: 50px auto;
  border: 3px ridge black;
}
.styles_wrapper__1kG3C {
  background: inherit !important;
  min-height: inherit !important;
}
figcaption {
  background: inherit !important;
}
figure img {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
  transition: 0.3s ease-in-out;
  border: 1px solid black;
  border-radius: 6%;
  margin: 10px;
}
figure img:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.styles_gridItem__nPXhF figure > div > img {
  opacity: 1 !important;
}
.styles_gridItem__nPXhF {
  margin: 30px;
}

.footer {
  height: 280px;
  background: #323336;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px dotted black;
  width: 100%;
}
.backButton {
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  font-size: 25px;
  left: 40px;
  top: 40px;
  display: inline-flex;
}
.backButton:hover {
  text-decoration: none;
  color: #2d3337;
  background: rgba(226, 226, 226, 0.815);
  border: 1px solid #2d3337;
  /* background:rgb(148, 147, 147); */
}

.googleMap {
  width: 400px;
  height: 260px;
}
.attestationVerte:hover {
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.page404 {
  font-size: 50px;
  margin-bottom: 482px;
  /* display: block;
  margin-top: 200px;
  margin-left: 704px; */
}
p {
  color: white;
  font-size: 23px;
}
i {
  margin-right: 3px;
}
.logo2 {
  width: 10%;
}
.footer p {
  color: white;
  font-size: 19px;
}
.contactDiv {
  display: flex;
  text-align: center;
  flex-direction: column;
  margin-top: 28px;
}
.rc-anchor-normal .rc-anchor-pt {
  text-align: center;
}
.flex700 {
  display: flex;
  align-items: center;
  margin: 100px 0px;
}
#effect1 {
  margin-right: 50px;
}
#effect2 {
  margin-left: 50px;
}

@media screen and (max-width: 1000px) {
  .logo {
    width: 60%;
    align-self: center;
    display: block;
    margin: 0 auto;
  }
  .logo2 {
    width: 30% !important;
  }
  .googleMap {
    display: none;
  }
  h1 {
    margin: 60px 0px !important;
  }
  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 32px;
    color: white;
    border-bottom: 1px solid black;
    width: 800px;
    margin: 50px auto !important;
    text-shadow: 8px 5px 7px black;
  }
  .flex700 img {
    width: 48% !important;
    margin-right: 20px !important;
    margin-left: 20px !important;
    height: 420px;
  }
  .divHack {
    display:block !important;
  }
  .mention1 {
    width: 80%;
    margin: 120px auto;
  }
  .footer {
    height: 285px;
  }
  .textPresentation {
    width: 80% !important;
    text-align: center !important;
  }
}

@media screen and (max-width: 700px) {
  h2 {
    width: 300px;
  }
  h1 {
    margin: 60px 0px !important;
  }
  .textPresentation {
    width: 80% !important;
    text-align: center !important;
  }
  .flex700 {
    flex-direction: column;
  }
  .flex700 img {
    width: 70% !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    height: 250px;
  }
  .carousel {
    width: auto !important;
  }
  .footer {
    height: 220px;
  }
  .mention1 {
    width: 80%;
    margin: 120px auto;
  }
  .backButton {
    left: 29px;
    top: 70px;
  }
  h3 {
    width:300px;
  }
  .footerMargin {
    padding-bottom: 160px;
  }
  .divHack {
    display:none !important;
  }
}
