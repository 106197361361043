.Form {
  display: flex;
  flex-direction: column;
  width: 500px;
  margin: 0 auto;
  align-items: center;
  margin-bottom: 100px;
  border: 2px dotted black;
}
.footerMargin {
  padding-bottom: 100px;
}
.input {
  margin: 12px;
  width: 380px;
  font-size: 22px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-radius: 5px;
  text-transform: capitalize;
}
.mail {
  margin: 12px;
  width: 380px;
  font-size: 22px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border-radius: 5px;
}
.textarea {
  height: 180px;
  font-size: 23px;
}
.rc-anchor-normal .rc-anchor-pt,
.rc-anchor-invisible .rc-anchor-pt,
.rc-anchor-compact .rc-anchor-pt {
  text-align: center;
}
.sendButton {
  width: 88px;
  box-shadow: 3px 3px 9px 0px black;
  margin: 30px;
  width: 200px;
}
label {
  color: white;
  font-size: 45px;
  font-family: inherit;
}

@media screen and (max-width: 600px) {
  .Form {
    width: 360px;
  }
  .input {
    width: 300px;
  }
}
